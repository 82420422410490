

@include is_project('bbi') {

  .b-hero-slider-two{

    .c-button{
      &.c-button--secondary{
       background: var(--color-primary);
      }
    }

    .tns-nav{
      button{
        &.tns-nav-active{
          background: var(--color-primary);
        }
      }
    }
    .b-hero-slider-two__title{
      font-weight: 500;
    }
  }

  .b-cta--no-image {
    background: var(--color-tertiary);
  }
}
